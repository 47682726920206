@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: clamp(13px, 1.3vw, 14px);
  line-height: 1.75rem;
}



.Home-Banner {
  background-image: linear-gradient(#653eff85, #653eff85), url('../public/assets/image/home-bg1.png');
  background-size: cover;
  object-fit: contain;
  background-position: center;
}

.youtube-Banner {
  background-image: linear-gradient(#653eff85, #653eff85), url('../public/assets/image/home-bg1.png');
  background-size: cover;
  object-fit: contain;
  background-position: center;
}


.gradient-text {
  background: linear-gradient(90deg, #653eff85, #19d0dd, #3eb8ff);
  -webkit-background-clip: text;
  color: transparent;
}

.gradient-text-banner {
  background: linear-gradient(90deg, #f5f5f5e5, #19d0dd, #d1ecfc);
  -webkit-background-clip: text;
  color: transparent;
}

.DiscordBanner {
  background-image: linear-gradient(#653eff85, #653eff85), url('../public/assets/image/home-bg1.png');
  background-size: cover;
  background-position: center;
  object-fit: contain;
}


.button {
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  width: fit-content;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
}

.button:hover {
  animation: ani 8s linear infinite;
  border: none;
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

.button:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.button:hover::before {
  filter: blur(20px);
}

.button:active {
  background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}


.rotating-image {
  animation: rotate 20s infinite linear;
}


.btn-animation:hover {
  background-color: rgb(252, 252, 252) !important;
  color: rgb(2, 11, 138) !important;
  animation: ani 0.5s infinite linear;
  cursor: pointer;
}

.about-banner {
  background-image: linear-gradient(#653eff85, #653eff85), url('../public/assets/image/home-bg1.png');
  background-size: cover;
  object-fit: contain;
  background-position: center;

}

.Math-Banner {
  background-image: linear-gradient(#653eff85, #653eff85), url('../public/assets/image/home-bg1.png');
  background-size: cover;
  object-fit: contain;
  background-position: center;

}

.arrow {
  padding: 10px;
  animation: arrow 1s infinite ease;
}

.home-Btn-anim {
  padding: 10px;
  animation: home-Btn-anim 1s infinite ease;
}

.circle {
  animation: rotate 3s infinite ease;
}


@keyframes arrow {
  0% {
    transform: translateY(0%);

  }

  60% {
    transform: translateY(-60%);
  }

  100% {
    transform: translateY(0%);
  }
}
@keyframes home-Btn-anim {
  0% {
    transform: scale(1);

  }

  60% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* Add this to your CSS or styles */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.clipath {
  clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 88%);
}

.arrow {
  color: white;
}


.spinner {
  width: 44px;
  height: 44px;
  animation: spinner-y0fdc1 2s infinite ease;
  transform-style: preserve-3d;
}

.spinner>div {
  background-color: rgba(0, 77, 255, 0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 2px solid #004dff;
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-22px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(22px);
}

@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}